/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Swal from "sweetalert2";
import cookie from "react-cookies";
class CancelOrder extends Component {
  constructor(props) {
    super(props);
    var UserId =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
        ? cookie.load("UserId")
        : "";
    this.state = {
      UserId: UserId,
    };
  }
  componentDidMount() {
    Swal.fire({
      title: "Error",
      html: "Order Cancelled",
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary waves-effect waves-light",
      },
      buttonsStyling: false,
    });
    setTimeout(() => {
      this.props.history.push("/myaccount");
    }, 1000);
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />
        <div className="rel">
          <div className="container">
            <div className="bluebar">
              <div className="member-status">
                <h1>Cancel Order</h1>
              </div>
              {/* Footer section */}
              <Footer {...this.props} />
              {/* Donate popup - end */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CancelOrder;
