/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    let voucherSlug = localStorage.getItem("voucherSlug");
    let voucherquantity = localStorage.getItem("voucherquantity");
    cookie.save("triggerOTP", "Yes", { path: "/" });
    let details = {
      voucherSlug: voucherSlug,
      quantity: voucherquantity,
      checkoutType: "buyvoucher",
      triggerFrom: "checkout",
    };
    var _this = this;
    setTimeout(function () {
      _this.props.history.push({
        pathname: "/",
        state: details,
      });
    }, 100);
  }

  render() {
    return (
      <div className="container">
        <div id="loading-indicator">
          <div class="lds-hourglass"></div>
        </div>
      </div>
    );
  }
}

export default Checkout;
