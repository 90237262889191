/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import { apiUrl, unquieID, headerconfig } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst } from "../Helpers/SettingHelper";
import Select from "react-select";
import coin from "../../common/images/coin.svg";
var qs = require("qs");
class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "History",
      activetab: "transaction",
      transactionList: [],
      cashvoucherList: [],
      productvoucherList: [],
      bannerList: [],
      pointEarnedHistory: [],
      companyList: [],
      selectedCompany: "",
      loading: true,
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }

    if (unquieID == "") {
      props.history.push("/home");
    }

    this.getTransData();
    this.getcashvoucherData();
    this.getProductvoucherData();
    this.pointEarnedHistory();
    this.loadCompanyList();
  }

  getTransData() {
    var postObject = {
      app_id: unquieID,
      customer_id: cookie.load("UserId"),
    };
    showLoaderLst("trans-page-inner", "class");
    axios
      .post(
        apiUrl + "customer/cust_transaction",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        hideLoaderLst("trans-page-inner", "class");
        if (res.data.status === "ok") {
          this.setState({ transactionList: res.data.result, loading: false });
        } else {
          this.setState({ transactionList: [], loading: false });
        }
      });
  }

  getcashvoucherData() {
    var postObject = {
      app_id: unquieID,
      customer_id: cookie.load("UserId"),
    };
    axios
      .post(
        apiUrl + "customer/topuphistory",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ cashvoucherList: res.data.result });
        }
      });
  }

  loadCompanyList() {
    var urlShringTxt = apiUrl + "settings/getCompanyList";
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        var selectedCompany = "";
        var result = res.data.result;
        if(result.length>0) {
          result.map((item)=> {
            if(item.unquieID===unquieID) {
              selectedCompany = item;
            }
          })
        }
        this.setState({
          companyList: res.data.result, selectedCompany:selectedCompany
        });
      }
    });
  }
  getProductvoucherData() {
    var urlShringTxt =
      apiUrl + "customer/getproductVoucher?custID=" + cookie.load("UserId");
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          productvoucherList: res.data.result,
        });
      }
    });
  }
  pointEarnedHistory() {
    var urlShringTxt =
      apiUrl + "customer/getPointCredit?custID=" + cookie.load("UserId");
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          pointEarnedHistory: res.data.result,
        });
      }
    });
  }
  componentDidMount() {
    $("body").addClass("hide-overlay");
  }

  nevTabFun(tabTxt, event) {
    event.preventDefault();
    this.setState({ activetab: tabTxt });
  }

  transactionList() {
    let transactionList = this.state.transactionList;
    var availRecord = 0;
    var transactionListHtml = "";
    if (Object.keys(transactionList).length > 0) {
      transactionListHtml = transactionList.map((transaction, rwInt) => {
        var selectedCompany = this.state.selectedCompany?.unquieID || "";
        if (
          selectedCompany === "" ||
          selectedCompany === transaction.uniqueID
        ) {
          availRecord = 1;
          var transAmount = transaction.transaction_qr_usered_amount;
          if(transaction.transaction_qr_type==='points' && transaction.transaction_qr_bonus_amount!==null) {
            transAmount = transaction.transaction_qr_usered_amount - transaction.transaction_qr_bonus_amount ;
          }
          return (
            <li key={rwInt}>
              <div className="hlm-lhs">
                {transaction.transaction_qr_type == "products" ? (
                  <p>{transaction.transaction_product_name}</p>
                ) : (
                  <p>Credits ( {transaction.transaction_qr_type==='points'?'Redeem':'Direct Pay'} )</p>
                )}
                <p>{transaction.company_name}</p>
                <span>{transaction.transaction_created_on}</span>
              </div>
              <div className="hlm-rhs">
                <strong>
                  {transaction.transaction_qr_type == "products" ? (
                    <>
                      {transaction.transaction_qr_usered_amount}{" "}
                      <img src={coin} />
                    </>
                  ) : (
                    <>${transAmount}</>
                  )}
                </strong>
              </div>
            </li>
          );
        } else {
          return "";
        }
      });

     
    } 
    if(availRecord===1) {
      return <ul>{transactionListHtml}</ul>;
    }
    else {
      return <div className="no-voucher"> No History Of Transactions </div>;
    }
  }

  walletcashvoucherList() {
    let cashvoucherList = this.state.cashvoucherList;
    var availRecord = 0;
    var cashvoucherListHtml = '';
    if (Object.keys(cashvoucherList).length > 0) {
      cashvoucherListHtml = cashvoucherList.map((item, rwInt) => {
        var selectedCompany = this.state.selectedCompany?.unquieID || "";
        if (selectedCompany === "" || selectedCompany === item.uniqueID) {
          availRecord = 1;
          return (
            <li key={rwInt}>
              <div className="hlm-lhs">
                <p>{item.wallettopup_display_name}</p>
                <p>{item.company_name}</p>
                <span>{item.wallettopup_created_on}</span>
              </div>
              <div className="hlm-rhs">
                <strong>${item.wallettopup_total_amount}</strong>
                <span>{item.wallettopup_total_credits} credits</span>
              </div>
            </li>
          );
        } else {
          return "";
        }
      });      
    }
    if(availRecord===1) {
      return <ul>{cashvoucherListHtml}</ul>;
    }   
    else {
      return <div className="no-voucher"> No History Of Cash Voucher </div>;
    }
  }

  productvoucherList() {
    let productvoucherList = this.state.productvoucherList;
    var availRecord = 0;
    var productvoucherListHtml = '';
    if (Object.keys(productvoucherList).length > 0) {
      productvoucherListHtml = productvoucherList.map((item, rwInt) => {
        var selectedCompany = this.state.selectedCompany?.unquieID || "";
        if (selectedCompany === "" || selectedCompany === item.uniqueID) {
          availRecord = 1;
          return (
            <li key={rwInt}>
              <div className="hlm-lhs">
                <p>{item.buyvoucher_display_name}</p>
                <p>{item.company_name}</p>
                <span>{item.buyvoucher_created_on}</span>
              </div>
              <div className="hlm-rhs">
                <strong>${item.buyvoucher_total_amount}</strong>
              </div>
            </li>
          );
        } else {
          return "";
        }
      });
      
    } 
    if(availRecord===1) {
      return <ul>{productvoucherListHtml}</ul>;
    }   
    else {
      return <div className="no-voucher"> No History Of Product Voucher </div>;
    }
  }

  pointEarnedHistoryList() {
    let pointEarnedHistory = this.state.pointEarnedHistory;
    var availRecord = 0;
    var pointEarnedHistoryHtml = '';
    if (pointEarnedHistory.length > 0) {
      pointEarnedHistoryHtml = pointEarnedHistory.map((item, rwInt) => {
        var selectedCompany = this.state.selectedCompany?.unquieID || "";
        if (selectedCompany === "" || selectedCompany === item.uniqueID) {
          availRecord = 1;
          return (
            <li key={rwInt}>
              <div className="hlm-lhs">
                <p>{item.company_name}</p>
                <span>{item.transaction_created_on}</span>
              </div>
              <div className="hlm-rhs">
                <strong>
                  {item.transaction_qr_earned_points}{" "}
                  <img src={coin} alt="coin" />
                </strong>
              </div>
            </li>
          );
        } else {
          return "";
        }
      });      
    }
    if(availRecord===1) {
      return <ul>{pointEarnedHistoryHtml}</ul>;
    }else {
      return <div className="no-voucher"> No History Of Points Earned </div>;
    }
  }
  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }
  render() {
    let activetab = this.state.activetab;
    return (
      <div className="main-div trans-page-inner">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="history-list">
              <div className="vouchers-nav">
                <ul>
                  <li
                    className={activetab == "transaction" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "transaction")}
                  >
                    <a href={() => false}>Transactions</a>{" "}
                  </li>
                  <li
                    className={activetab == "cashvoucher" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "cashvoucher")}
                  >
                    <a href={() => false}>Cash Voucher</a>{" "}
                  </li>
                  <li
                    className={activetab == "productvoucher" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "productvoucher")}
                  >
                    <a href={() => false}>Product Voucher</a>{" "}
                  </li>
                  <li
                    className={activetab == "pointhistory" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "pointhistory")}
                  >
                    <a href={() => false}>Point History</a>{" "}
                  </li>
                </ul>
              </div>

              <div className="history-filter"></div>
              <div className="history-list-main">
                {this.state.loading === true ? (
                  <ContentLoader
                    speed={2}
                    width={400}
                    height={160}
                    viewBox="0 0 400 160"
                    backgroundColor="#d9d9d9"
                    foregroundColor="#ededed"
                    {...this.props}
                  >
                    <rect x="50" y="6" rx="4" ry="4" width="343" height="10" />
                    <rect x="8" y="6" rx="4" ry="4" width="35" height="10" />
                    <rect x="50" y="55" rx="4" ry="4" width="343" height="10" />
                    <rect x="8" y="55" rx="4" ry="4" width="35" height="10" />
                    <rect
                      x="50"
                      y="104"
                      rx="4"
                      ry="4"
                      width="343"
                      height="10"
                    />
                    <rect x="8" y="104" rx="4" ry="4" width="35" height="10" />
                  </ContentLoader>
                ) : (
                  <>
                    <Select
                      value={this.state.selectedCompany}
                      onChange={this.handleSelectChange.bind(
                        this,
                        "selectedCompany"
                      )}
                      placeholder="Select Company"
                      isClearable={true}
                      options={this.state.companyList}
                    />
                    {activetab === "transaction" && this.transactionList()}
                    {activetab === "cashvoucher" &&
                      this.walletcashvoucherList()}
                    {activetab === "productvoucher" &&
                      this.productvoucherList()}
                    {activetab === "pointhistory" &&
                      this.pointEarnedHistoryList()}
                  </>
                )}

                {/*<ul>
                  <li>
                    <div className="hlm-lhs" style={{textAlign:"center",width:"100%"}}>
                      <p>&nbsp;</p>
                      <span>No History Of Transactions</span>
                    </div>
                  </li>
                </ul>*/}
                {/*<ul>
                  <li>
                    <div className="hlm-lhs">
                      <p>Wallet Topup</p>
                      <span>23/10/2023</span>
                    </div>
                    <div className="hlm-rhs">
                      <strong>$150</strong>
                    </div>
                  </li>
                  <li>
                    <div className="hlm-lhs">
                      <p>Purchased $8 off cash voucher</p>
                      <span>23/10/2023</span>
                    </div>
                    <div className="hlm-rhs">
                      <strong>
                        1000 <img src={coin} />
                      </strong>
                    </div>
                  </li>
                  <li>
                    <div className="hlm-lhs">
                      <p>Wallet Topup</p>
                      <span>23/10/2023</span>
                    </div>
                    <div className="hlm-rhs">
                      <strong>$100</strong>
                    </div>
                  </li>
                  <li>
                    <div className="hlm-lhs">
                      <p>Purchased $10 off cash voucher</p>
                      <span>23/10/2023</span>
                    </div>
                    <div className="hlm-rhs">
                      <strong>
                        1500 <img src={coin} />
                      </strong>
                    </div>
                  </li>
                  <li>
                    <div className="hlm-lhs">
                      <p>Purchased Chicken Rice voucher</p>
                      <span>23/10/2023</span>
                    </div>
                    <div className="hlm-rhs">
                      <strong>
                        2000 <img src={coin} />
                      </strong>
                    </div>
                  </li>
                </ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default History;
