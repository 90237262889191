import React, { Component } from "react";
class RedeemQR extends Component {
  constructor(props) {
    super(props);
    let slugval =
      typeof this.props.match.params.slugval !== "undefined"
        ? this.props.match.params.slugval
        : "";
    if (slugval !== "") {
      localStorage.setItem("qrcodetxt", slugval);
      if (this.props.location.state?.redeemAmount !== "") {
        var details = {
          redeemAmount: this.props.location.state?.redeemAmount,
        };
        this.props.history.push({
          pathname: "/redeemqrcode",
          state: details,
        });
      } else {
        this.props.history.push("/redeemqrcode");
      }
    } else {
      this.props.history.push("/");
    }
  }
  render() {
    return <div></div>;
  }
}

export default RedeemQR;
