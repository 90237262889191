/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  apiUrl,
  headerconfig,
  stripeReference,
  unquieID,
} from "../Settings/Config";
import Swal from "sweetalert2";
import { hideLoaderLst, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import loadingImage from "../../common/images/loading_popup.gif";
var Parser = require("html-react-parser");
var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    var paymentrefID = this.props.match.params?.refID || "";
    var UserId =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
        ? cookie.load("UserId")
        : "";
    this.state = {
      paymentrefID: paymentrefID,
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: "We are processing your order",
      UserId: UserId,
      checkoutType: "",
    };
  }
  componentDidMount() {
    this.placeOrder();
    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );
  }

  placeOrder() {
    var history = this.props.history;
    var urlParams = new URLSearchParams(this.props.location.search);
    var transactionID = urlParams.get("transaction_id") || "";
    if (this.state.paymentrefID !== "") {
      var postObject = {};
      postObject = {
        app_id: unquieID,
        payment_refID: this.state.paymentrefID,
        customer_id: this.state.UserId,
      };

      axios
        .post(
          apiUrl + "paymentV2/mswipecheckstatus",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          $.magnificPopup.close();
          if (res.data.status === "ok") {
            this.setState({ checkoutType: res.data.payment_type });
            if (res.data.payment_type === "buyvoucher") {
              this.placeOrderBuyVoucher();
            } else if (res.data.payment_type === "topup") {
              this.placeOrderTopup();
            }
          } else {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
            this.props.history.push("/");
          }
        });
    } else if (transactionID !== "") {
      var payType = cookie.load("payType");

      cookie.remove("payment_intent", { path: "/" });
      cookie.remove("payType", { path: "/" });

      var postObject = {};
      postObject = {
        app_id: unquieID,
        payment_refID: transactionID,
        customer_id: this.state.UserId,
      };
      axios
        .post(
          apiUrl + "paymentV2/checkStatusReddot",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          $.magnificPopup.close();
          if (res.data.status === "ok") {
            this.setState({ checkoutType: res.data.payment_type });
            if (payType == "Subscription") {
              Swal.fire({
                title: "Success",
                html: "Subscription Created Success",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary waves-effect waves-light",
                },
                buttonsStyling: false,
              });
              this.props.history.push('/subscription-history')
            } else {
              if (res.data.payment_type === "buyvoucher") {
                this.placeOrderBuyVoucher();
              } else if (res.data.payment_type === "topup") {
                this.placeOrderTopup();
              }
            }
          } else {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
            this.props.history.push("/");
          }
        });
    } else if (
      (urlParams.get("redirect_status") == "succeeded" ||
        urlParams.get("redirect_status") == "processing") &&
      urlParams.get("payment_intent") == cookie.load("payment_intent")
    ) {
      var postObject =
        window.sessionStorage.getItem("postObject") +
        "&payment_intent=" +
        cookie.load("payment_intent") +
        "&preference=" +
        stripeReference;
      var payType = cookie.load("payType");

      cookie.remove("payment_intent", { path: "/" });

      if (payType == "Subscription") {
        axios
          .post(
            apiUrl + "subscription/createSubscription",
            postObject,
            headerconfig
          )
          .then((res) => {
            hideLoaderLst("place-order-link", "class");
            if (res.data.status === "ok") {
              setTimeout(function () {
                $.magnificPopup.close();
                showAlert("Success", res.data.message, "success");
                history.push("/myaccount");
              }, 3000);
            } else {
              setTimeout(
                function () {
                  this.setState({
                    processingText: res.data.message,
                  });
                }.bind(this),
                300
              );
              setTimeout(function () {
                $.magnificPopup.close();
                history.push("/myaccount");
              }, 3000);
            }
          });
      }
    } else if (urlParams.get("status") == "failure") {
      setTimeout(
        function () {
          this.setState({
            processingText:
              "Please check in myaccount, to get <br/>order details.",
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        $.magnificPopup.close();
        history.push("/checkout");
      }, 3000);
    } else {
      setTimeout(
        function () {
          $.magnificPopup.close();
          showAlert(
            "Alert",
            "Please check in myaccount, to get <br/>order details.",
            "error"
          );
          history.push("/myaccount");
        }.bind(this),
        300
      );
    }
  }

  placeOrderBuyVoucher() {
    var postObject =
      window.sessionStorage.getItem("postObject") +
      "&paymentrefID=" +
      this.state.paymentrefID;
    console.log(postObject, "postObjectpostObjectpostObject");
    axios
      .post(apiUrl + "wallettopup/topup", postObject, headerconfig)
      .then((res) => {
        if (res.data.status === "ok") {
          var voucherType = localStorage.getItem("voucherType");
          console.log(voucherType, 'voucherType')
          localStorage.removeItem("influencerBuyNow");
          localStorage.removeItem("planData");
          localStorage.removeItem("voucherSlug");
          localStorage.removeItem("voucherquantity");
          localStorage.removeItem("influencerID");
          localStorage.removeItem("voucherType");
          localStorage.removeItem("paymentRequestId");
          localStorage.removeItem("shareProductID");
          localStorage.removeItem("analyticsType");

          var msg = "";
          if (voucherType === "C") {
            msg = "Your cash voucher has been successfully purchased.";
          } else if (voucherType === "F") { 
          msg = "Your product voucher has been successfully purchased.";
           }
          Swal.fire({
            title: "Success",
            html: msg,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          if (voucherType === "C") {
            this.props.history.push("/myaccount");
          } else {
            this.props.history.push("/vouchers/available");
          }
        } else {
          Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/");
        }
      });
  }

  placeOrderTopup() {
    var postObject =
      window.sessionStorage.getItem("postObject") +
      "&paymentrefID=" +
      this.state.paymentrefID;
    axios
      .post(apiUrl + "wallettopup/topup", postObject, headerconfig)
      .then((res) => {
        if (res.data.status === "ok") {
          localStorage.removeItem("planData");
          Swal.fire({
            title: "Success",
            html: "Topup successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });

          this.props.history.push("/myaccount");
        } else {
          Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/refpage/checkout");
        }
      });
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="bluebar">
              <div className="member-status">
                <h1>Placing Order</h1>
              </div>

              <div
                id="processing-popup"
                className="white-popup mfp-hide popup_sec processing"
              >
                <div className="pouup_in">
                  <h3 className="title1 text-center">
                    {this.state.processingText !== ""
                      ? Parser(this.state.processingText)
                      : ""}
                  </h3>
                  <div className="process_inner">
                    <div className="process_col">
                      <div className="process_left">
                        <img src={this.state.validateimage} />
                      </div>
                      <div className="process_right">
                        <h5>Waiting for Payment Confirmation</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer section */}
              <Footer {...this.props} />
              {/* Donate popup - end */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Placeorder;
